type EventName =
  | "directory__open_ref"
  | "profile__open_ref"
  | "suggestions__open_ref"
  | "unit__open_ref"
  | "unit__open_ref__ready_to_invest"
  | "visit__unit";

interface EventPayloads {
  directory__open_ref: { value?: string };
  profile__open_ref: { value?: string };
  suggestions__open_ref: { value?: string };
  unit__open_ref: { value?: string };
  unit__open_ref__ready_to_invest: { value?: string };
  visit__unit: { value?: string };
}

export const gtagReachGoal: {
  [K in EventName]: (payload: EventPayloads[K]) => void;
} = {
  directory__open_ref: (payload) =>
    validateAndSend("directory__open_ref", payload),
  profile__open_ref: (payload) => validateAndSend("profile__open_ref", payload),
  suggestions__open_ref: (payload) =>
    validateAndSend("suggestions__open_ref", payload),
  unit__open_ref: (payload) => validateAndSend("unit__open_ref", payload),
  unit__open_ref__ready_to_invest: (payload) =>
    validateAndSend("unit__open_ref__ready_to_invest", payload),
  visit__unit: (payload) => validateAndSend("visit__unit", payload),
};

let counter = 0;

async function validateAndSend<T extends EventName>(
  eventName: T,
  payload: EventPayloads[T]
): Promise<void> {
  const { $gtag } = useNuxtApp();
  const gtag = $gtag as unknown;
  const resolvedGtag = gtag as any;

  if (!resolvedGtag) {
    if (!counter) {
      setTimeout(() => {
        validateAndSend(eventName, payload);
        counter = counter + 1;
      }, 2000);
    }
    console.error("GTag не был загружен.");
    return;
  }

  try {
    if (!gtagReachGoal[eventName]) {
      // console.error(
      //   `Событие "${eventName}" не зарегистрировано в gtagReachGoal.`
      // );
      return;
    }

    if (typeof payload !== "object") {
      // console.error(`Некорректные данные для события "${eventName}".`);
      return;
    }

    resolvedGtag("event", eventName, { ...payload });
    // console.log(`Событие "${eventName}" успешно отправлено:`, payload);
  } catch (error) {
    console.log("error: ", error);
    // console.error("Ошибка при работе с GTag:", error);
  }
}
